import axios from "./http";


//文章查询
export function GetSpecial(type) {
    return axios({
        url: `/datalyg/view/specialClass/${type}`,
        method: "get"
    })
}

//资质轮播
export function GetBanners() {
    return axios({
        url: `/datalyg/view/banners`,
        method: "post"
    })
}



//文章查询
export function GetCustom(type) {
    return axios({
        url: `/datalyg/view/custom/${type}`,
        method: "get"
    })
}
